<script setup lang="ts">
const client = useCrystallizeClient();

const res = useAsyncData(() => client.navigation.byFolders("/", "no-nb", 3));
</script>

<template>
  <div>
    <h1>Hello from the pages directory!</h1>

    <pre
      v-if="res.data.value"
      v-text="JSON.stringify(res.data.value, null, 2)"
    />
    <pre
      v-else
      v-text="res.error"
    />
  </div>
</template>
