import {
  createClient,
  createCatalogueFetcher,
  createNavigationFetcher,
  createProductHydrater, createOrderFetcher,
} from "@crystallize/js-api-client";

export function useCrystallizeClient() {
  const config = useRuntimeConfig();

  const client = createClient({
    tenantIdentifier: config.public.crystallize.tenant,
    accessTokenId: config.crystallize?.accessTokenId,
    accessTokenSecret: config.crystallize?.accessTokenSecret,
  });

  return {
    catalogue: createCatalogueFetcher(client),
    navigation: createNavigationFetcher(client),
    products: createProductHydrater(client),
    orders: createOrderFetcher(client),
  };
}
